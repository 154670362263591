<template>
  <div v-resize="onResize" :class="['b-card-invoice', orientation]">
    <v-btn fab class="b-card-invoice__btn">
      <v-icon color="primary">{{ icon }}</v-icon>
    </v-btn>

    <div class="b-card-invoice__content">
      <h3 class="b-card-invoice__content--title">{{ title }}</h3>

      <div class="b-card-invoice__content__info">
        <span
          class="b-card-invoice__content__info--total"
          v-if="typeof total === 'number'"
        >
          {{ formatMoney(total) }}
        </span>
        <span class="b-card-invoice__content__info--total">
          {{ amount }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from '@/helpers/formatting';

export default {
  props: {
    icon: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    total: {
      type: [String, Number],
      require: false
    },
    amount: {
      type: Number,
      require: false
    },
    align: {
      type: String,
      default: 'column'
    }
  },

  data: () => ({
    innerWidth: 0
  }),

  computed: {
    orientation() {
      return this.align === 'column' && this.innerWidth >= 600
        ? 'b-card-invoice__column'
        : 'b-card-invoice__row';
    }
  },

  methods: {
    formatMoney,

    onResize() {
      this.innerWidth = window.innerWidth;
    }
  }
};
</script>
